<template>
    <div>
        <div
            v-show="state.form.status !== 'success'"
            class="spinner"
        >
            Loading users...
        </div>

        <div
            v-if="state.form.status === 'success'"
        >

            <table>
                <tr>
                    <th>id</th>
                    <th>name</th>
                    <th>email</th>
                    <th>role</th>
                </tr>

                <tr
                    v-for="user in state.form.data"
                    v-bind:key="user"
                >
                    <td>{{ user.id }}</td>
                    <td>{{ user.name }}</td>
                    <td>{{ user.email }}</td>
                    <td>{{ user.role }}</td>
                </tr>
            </table>


        </div>
    </div>
</template>

<script>
    import {reactive } from 'vue';
    import {onMounted} from 'vue';
    //import {useStore } from 'vuex';
    import axios       from 'axios';
    import {useAuth  } from '@websanova/vue-auth/src/v3.js';
    import useAuthComp from '../../../src/composables/useAuthComp.js';

    export default {
        setup() {
            const auth     = useAuth();
            //const store    = useStore();
            const authComp = useAuthComp();

            const state = reactive({
                form: {
                    status: null,
                    data: null
                }
            });

            onMounted(() => {
                getUsers();
            });

            function getUsers() {
                state.form.status = 'loading';

                axios({
                    url: 'users'
                })
                .then((res) => {
                    console.log(res.data.data)
                    state.form.data = res.data.data;

                    state.form.status = 'success';
                });
            }

            function impersonateDefault(user) {
                auth
                .impersonate({
                    url: 'auth/' + user.id + '/impersonate',
                    redirect: {name: 'user-account'},
                });
            }

            function impersonateComp(user) {
                authComp.impersonate({user: user});
            }

            return {
                state,
                impersonateComp,
                impersonateDefault,
            }
        }
    }
</script>
